#launchpadContent{
    padding-top: 18vh;
    align-items: flex-start;
    padding-bottom: 25vh;
    position: relative;
}
.gif-section{
    width: 100%;
    height: 400px;
}
.custom-modal img{
    width: 100%;
    margin-bottom: 15px;
}

#gridBg{
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0;
    z-index: -10;
}
h1 img{
    max-width: 250px;
    width: 80%;
    margin-right: 15px;
}
#launchpadContent #leftSide{
}
.container{
    max-width: 80% !important;
}
#l2{
    position: absolute;
    right: 0;
    filter: invert(1);
    width: 120px;
}
#l1{
    position: absolute;
    width: 80px;
    left: -50px;
    transform: rotate(-115deg);
    top: 80vh;
}
.tab-content{
    padding-top: 40px;
}
.spanItem label{
    font-size: 14px;
    opacity: 0.8;
}
#launchpadContent #leftSide h2{
    max-width: 100%;
    color: #b1abd3;
    font-size: 16px;
    line-height: 27px;
    font-family: 'CourierPrime';
    padding-bottom: 15px !important;
}
.tab-content>.active{
    border-bottom: none !important;
}

#launchpadContent #leftSide h1::after{
    display: none;
}

#launchpadContent .btnWrapper{
    display: flex;
    align-items: center;
    max-width: 100%;
}

.connectWallet small{
    display: block;
    font-weight: 100;
    font-size: 9px;
    opacity: 0.8;
}
.nav-tabs[role='tablist']{
    border: none !important;
}
.nav-tabs .nav-link[role='tab']{
    color: #e4e4e7;
    font-weight: 400;
    font-size: 17px;
    padding: 0px !important;
    margin-right: 25px !important;
    outline: none !important;
    border: none !important;
    border-color: #3e3ad3 !important;
    transition: 0.4s all;
    padding-right: 20px !important;
}
.nav-tabs .nav-link[role='tab'].active{
    font-weight: 700;
    background: transparent;
    opacity: 1 !important;
    color:#E8481D !important;
    border-bottom: 3px solid #E8481D !important;
}
#launchpadContent .connectWallet{
    padding: 8px 20px;
    border-radius: 5px;
    margin: 0;
    font-size: 15px;
}.team-item-section{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.team-item{
    text-align: left;
    flex: 0 0 50%;
}
.team-item.r{
    text-align: right !important;
}
.team-item.r .social{
    justify-content: flex-end;
}
.team-item.r .social img{
    margin-right: 0 !important;
    margin-left: 15px;
}
.mint-section{
    position: absolute;
    bottom: 10px;
    right: 15px;
}
@keyframes beat-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.02);}
    40%   {transform:scale(0.97);}
    75%   {transform:scale(1.05);}
    100%  {transform:scale(1);}
}

#topSpan{
    margin-top: 20px;
}
#topSpan span{
    border-radius: 5px;
    background: transparent;
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid #6460ff;
    color: #6169ff;
    margin-bottom: 10px;
    display: inline-block;
    
    border-radius: 5px;
    background: white;
    font-weight: 900;
    border-radius: 5px;
    border: 1px solid #ee7c19;
    color: #da8f30;
    margin-bottom: 10px;
    display: inline-block;
    padding: 3px 8px;
    margin: 5px;
    box-shadow: 2px 2px 5px #8c3c1d52;

}
#launchpadContent .btnWrapper span{
    font-size: 15px;
    font-weight: 700;
    opacity: 1;
}

#loader img{
    width: 100px;
}
#loader p{
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 } 
#loader{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: black;
}
#projectError{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: 700;
    text-shadow: 2px 2px 15px #3333d7;
}
#projectError div{
    animation: b-animation 2s infinite;
}
@keyframes b-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.05);}
    40%   {transform:scale(0.9);}
    75%   {transform:scale(1.1);}
    100%  {transform:scale(1);}
}
#projectBack{
    position: absolute;
    margin-top: -60px;
    font-size: 24px;
}

.social-item{
    padding: 0;
    margin: auto;
    padding: 8px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}
.social-item img{
    width: 16px;
    padding-left: 0;
    transition: 0.4s all;
    filter: brightness(5);
}
#enleapLogo{
    width: 25px;
    filter: brightness(5);
    margin-bottom: -3px;
}
.social-item a{
    padding-left: 0 !important;
    padding-right: 25px !important;
}
.social-item img:hover{
    transform: scale(1.15);
    opacity: 0.7;
}
@media (max-width: 960px) {
    .container{
        max-width: 90% !important;
    }
    #rightSide{
        flex: 2;
    }
    #leftSide {
        flex: 5;
    }
    .nav-tabs .nav-link[role='tab']{
        font-size: 20px !important;
        padding: 5px 15px !important;
        margin-right: 20px !important;
    }
    #home{
        flex-direction: column;
    }
    #leftSide h2{
        padding: 15px 0 50px 0;
        max-width: 100%;
    }
    #imageWrapper{
        width: 80%;
        justify-content: center;
        margin:auto;
    }
   
    .btnWrapper{
        margin: 0 auto;
    }
    #aboutSection{
        background-size: cover;
    }
}

@media (max-width: 800px) {
    #leftSide{
        width: 100%;
        order: 2;
        margin: 50px 0;
    }
    
    #rightSide{
        width: 100%;
        order: 1;
        margin-top: 30px;
    }
    .container{
        max-width: 90% !important;
    }
    #rightSide{
        top: 0 !important;
    }
    #rightSide{
        position: relative !important;
    }
    #launchpadContent, #launchpadContent .btnWrapper{
        flex-direction: column;
    }
    #launchpadContent .btnWrapper span{
        margin-top: 25px;
        font-size: 15px;
    }
    #launchpadContent #leftSide{
        padding: 0px 5% !important;
    }
    #imageWrapper{
        width: 50%;
        margin-bottom: 100px;
    }
    #launchpadContent{
        padding-top: 2vh;
    }
}

@media (max-width: 560px) {
    .container{
        max-width: 100% !important;
    }
    .progress-container{
        max-width: 90% !important;
    }
    #leftSide .nav{
        flex-direction: column;
    }
    #leftSide .nav button{
        width: 100%;
    }
    #imageWrapper{
        width: 90%;
        margin-bottom: 100px;
    }
    #leftSide{
        margin: 80px 0 50px 0;
    }
    .nav-tabs .nav-link[role='tab']{

    }
    #bottomSpan{
        flex-wrap: wrap;
    }
    .spanItem{
        margin-bottom: 15px;
    }
    .mint-section{
        position: relative;
        bottom: 0;
        right: 0;
        margin-top: 10px;
        margin-bottom: 60px;
    }
    .mint-info-list .countdown{
        bottom: 15px !important;
        top: initial !important;
    }
    .countdown span{
        margin-bottom: 0 !important;
    }
}
.hue{
    filter: hue-rotate(380deg);
}

#gif-container{
    overflow: hidden;
    
}
#gif-container img{
    width: 100%;
    filter: brightness(0.8);

}


.custom-modal .modal-dialog{
    position: absolute!important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important;
    margin: 0!important;
    width: auto;
    height: auto;
}
.promptClose {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: rgb(206, 206, 206);
}
.modal-content{
    height: 100%;
    border-radius: 15px !important;
    background: #000000 !important;
    box-shadow: 0px 0px 15px #151515;
}
.promptClose{
    font-weight: 900;
}
#l4{
    filter: brightness(5);
    width: 100px;
    margin-bottom: 20px; 
}
.hide{
    display: none !important;
}


.modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-body p{
    color: #e2e2e2;
    font-size: 13px;
    width: 80%;
    margin:auto;
    text-align: center;
}
.modal-backdrop.show{
    opacity: 0.7 !important;
}
.prompt-modal .modal-body{
    padding: 25px 60px;
}
#promptMintBtn{
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
    margin-top: 25px;
}
.promptBody{
    text-align: center;
    display: flex;
    align-items: center;
}
#l5{
    width: 200px;
    margin: auto;
}
.promptBody p{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
}
.promptBody h3{
    margin-bottom: 25px;
}
#mintQuantity{
    min-width: 200px;
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    margin-top: 5px;
    color: var(--default-font-color) !important;
}
.prompt-modal .modal-dialog{
    height: auto;
    width: auto !important;
}
.promptBody{
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
}
.promptBody h3{
    margin-top: 25px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
}
.fa-item {
    padding: 5%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.fa-item img {
    width: 50px;
    margin-bottom: 15px;
}

.fa-item h3 {
    font-weight: 800;
    font-size: var(--header-font-size);
}

.fa-item p {
    font-size: var(--paragraph-font-size);
}

.fa-item button {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
    padding: 15px 60px;
    color: black;
    text-align: left;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #E8481D !important;
    text-align: center;
    border-radius: 100%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    left: 15px;
    top: 17px;
}
.form-control[type='file']{
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    color: var(--default-font-color) !important;
}
.fa-step h3 {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.9;
    margin-bottom: 10px;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background:#E8481D !important;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background-color: transparent !important;
}





.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
    padding-bottom: 0px !important;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #2116ce;
    text-align: center;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    left: 15px;
    top: -3px;
}

.fa-step h3 {
    font-size: 18px;
    font-weight: 700;
    opacity: 0.9;
    margin-bottom: 0;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
    margin-bottom: 8px;
}
span.status{
    background: #e8481d;
    border-radius: 30px;
    padding: 3px 9px;
    font-size: 10px;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #4c4d5d;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background: transparent !important;
    height: 80% !important;
}


.powered h3{
    text-align: right;
}

#roadmapContent h3{
    font-size: 14px;
    font-family: 'CourierPrime' !important;
    color: white;
    font-weight:500;
    margin-bottom: 15px;
    margin-top: -14px;
}
#roadmapContent p{
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    color: #8d8d8d;
}
#rightSide{
    top: 100px;
    flex: 3;
}
#rightSide img{
    width: 100%;
}
#leftSide{
    flex:5;
}
#teamContent .social{
    display: flex;
    margin-bottom: 15px;
}
#teamContent .social img{
    width: 18px;
    margin-right: 15px;
}
#teamContent .desc{
    font-size: 14px;
    color: white;
    opacity: 0.6;
    margin-bottom: 25px;
}
#teamContent .role{
    font-size: 12px;
    background: linear-gradient(46deg, #27293d 0%, rgb(67 103 162) 100%);
    color: white;
    border-radius: 4px;
    padding: 2px 10px;
}
#teamContent .name{
    font-size: 18px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 5px;
    color: white;
}
#teamContent h3{
    background: #18182a;
    font-size: 16px;
    font-weight: 600;
    padding: 25px;
    line-height: 25px;
    color: #d2d2d2;
    margin-bottom: 50px;
    border-radius: 5px;
}

#teamBg{
    background: linear-gradient(76deg, rgb(238 124 25) 0%, rgb(184, 69, 20) 31%, rgb(174, 57, 11) 59%, rgb(238 124 25) 98%) !important;
    color: white !important;
    position: relative;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 2px 2px 30px #401c0c !important;
    padding: 25px 40px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'CourierPrime';
    
}

.mint-info-list{
    margin-bottom: 15px;
    border-radius: 15px;
    padding: 10px 20px;
    position: relative;
    border: 1px solid #5c5c5c;
    text-align: left;
}
.mint-info-list span{
    background:  #252525;
    font-size: 11px;
    border-radius: 30px;
    padding: 3px 8px;
    color: #ededed;
    font-weight: 700;
    margin-bottom: 25px;
    margin-right: 8px;
}
.mint-info-list span.wl{
    background: #b1abcd;
    color: white;
}
.mint-info-list span.nwl{
    background-color: #d62424;
}
.burn-item{
    border: 1px solid #2d2d47;
    background: rgba(0,0,0,0.4);
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
}
.burn-item img{
    width: 80%;
    border-radius: 8px;
    margin-top: 15px;
}
.burn-item h4{
    font-size: 17px;
    font-weight: 700;
    color: white !important;
    opacity: 1;
    padding-bottom: 5px !important;
    padding-top: 15px;
}
.burn-item p{
    color: white;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 50px;
}

.burn-item button{
    background: #3e3ad3;
    padding: 5px 15px;
    width: auto;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    margin: auto;
    left: 0;
    right: 0;
    width: 150px;
}
.mint-info-list .mint-info-content {
    margin-top: 50px;
    font-weight: 400;
    font-size: 11px;
    color: rgb(103 103 103);
}
.mint-info-list label{
    padding-left: 8px;
    color: white !important;
    padding-right: 8px;
    font-weight: 800;
}
.mint-info-list b{
    
    padding: 0px 5px;

}
.countdown p{
    font-size: 13px;
    color: #909090;
    font-weight: 400;
}
.mint-info-list .countdown{
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
}

.countdown span{
    background: transparent;
    font-weight: 800;
    font-size: 13.5px;
    padding: 0;
    margin-left: 10px;
    color: white;
}


.custom-tooltip {
    position: relative;
    cursor: pointer;
}

.custom-tooltip .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    max-width: 400px;
    width: max-content;
    background-color: #09090f;
    color: #fff;
    padding: 10px 25px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 13px;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 0.5s;
}

.custom-tooltip:hover .tooltiptext,  .tooltiptext:hover {
visibility: visible;
opacity: 1;
}
.tooltipTitle{
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin-bottom: 5px;
    display: block;
}
.tooltipContent{
    opacity: 0.6;
    margin-bottom: 15px;
}
.tooltiptext a{
    width: 100%;
    text-align: right;
    display: block;
    color: #ee7c19 ;
}



#panda1{
    position: fixed;
    width: 250px;
    bottom: -50px;
    left: -150px;
    animation: panda1 18s infinite;
    filter: brightness(0.8);
}

@keyframes panda1 {
    0%    {left:-150px; bottom: -45px;}
    2%    {bottom: -50px;}
    4%    {bottom: -45px;}
    6%    {bottom: -50px;}
    8%    {bottom: -45px;}
    10%    {bottom: -50px;}
    12%    {bottom: -45px;}
    14%    {bottom: -50px;}
    16%    {bottom: -45px;}
    18%    {bottom: -50px;}
    20%    {bottom: -45px;}
    22%    {bottom: -50px;}
    24%    {bottom: -45px;}
    26%    {bottom: -50px;}
    28%    {bottom: -45px;}
    30%    {bottom: -50px;}
    32%    {bottom: -45px;}
    34%    {bottom: -50px;}
    36%    {bottom: -45px;}
    38%    {bottom: -50px;}
    40%    {bottom: -45px;}
    42%    {bottom: -50px;}
    44%    {bottom: -45px;}
    46%    {bottom: -50px;}
    48%    {bottom: -45px;}
    50%  {left:50px; }
    70%  {left:50px; bottom: -45px;}
    75% {bottom: -250px; left: 50px;}
    76% {bottom: -250px; left: -150px;}
}
#panda2{
    position: fixed;
    width: 380px;
    bottom: -50px;
    left: -250px;
    animation: panda2 28s infinite;
}

@keyframes panda2 {
    0%    {left:-250px; bottom: -45px;}
    2%    {bottom: -50px;}
    4%    {bottom: -45px;}
    6%    {bottom: -50px;}
    8%    {bottom: -45px;}
    10%    {bottom: -50px;}
    12%    {bottom: -45px;}
    14%    {bottom: -50px;}
    16%    {bottom: -45px;}
    18%    {bottom: -50px;}
    20%    {bottom: -45px;}
    22%    {bottom: -50px;}
    24%    {bottom: -45px;}
    26%    {bottom: -50px;}
    28%    {bottom: -45px;}
    30%    {bottom: -50px;}
    32%    {bottom: -45px;}
    34%    {bottom: -50px;}
    36%    {bottom: -45px;}
    38%    {bottom: -50px;}
    40%    {bottom: -45px;}
    42%    {bottom: -50px;}
    44%    {bottom: -45px;}
    46%    {bottom: -50px;}
    48%    {bottom: -45px;}
    50%  {left:120px; }
    70%  {left:120px; bottom: -45px;}
    75% {bottom: -350px; left: 120px;}
    76% {bottom: -350px; left: -250px;}
}
#panda3{
    transform: rotateY(180deg);
    position: fixed;
    width: 350px;
    bottom: -50px;
    right: 50px;
    animation: panda3 25s infinite;
}
@keyframes panda3 {
    0%    {transform:scale(1)  rotateY(180deg); filter: brightness(1);}
    20%    {transform:scale(0.95)  rotateY(180deg); bottom: -55px; filter: brightness(0.8);}
    60%    {transform:scale(1)  rotateY(180deg); bottom:-50px; filter: brightness(1);}
    80%    {transform:scale(0.9)  rotateY(180deg); bottom:-60px; filter: brightness(0.6);}
  
}

#panda5{
    transform: rotateX(180deg);
    position: fixed;
    width: 100px;
    top: -200px;
    right: 50px;
    right: 0;
    left: 0px;
    margin:auto;
    animation: panda5 15s infinite;
}
@keyframes panda5 {
    0%    {top:-200px; filter: brightness(0.5);}
    30%  { top:-200px; filter: brightness(0.5);}
    35%   {top:-80px; filter: brightness(0.5);}
    38%   {top:-85px; filter: brightness(0.5);}
    43%   {top:-25px; filter: brightness(1);}
    50%   {top:-20px}
    55%  {top:-200px}
}
.mint-info-list.highlight{
    border: 1px solid #5c5c5c;
}
#panda6{
    transform: rotate(90deg);
    position: fixed;
    width: 100px;
    top: 0px;
    left: -200px;
    animation: panda6 26s infinite;
}
@keyframes panda6 {
    0%    {left:-200px; filter: brightness(0.5);}
    30%  { left:-200px; filter: brightness(0.5);}
    35%   {left:-80px; top:0px; filter: brightness(0.1);}
    68%   {left:-55px; top: 150px; filter: brightness(0.5);}
    78%   {left:-25px; top: 150px; filter: brightness(1);}
    88%   {left:-15px; top: 150px; filter: brightness(1);}
    75%  {left:-200px; top: 150px;}
}

.nav-item button{
}

.powered{
    font-size: 14px;
    color: black;
}
.powered img{
    width: 30px;
    margin-bottom: -7px;
    margin-left: 0px;
    filter: none;
}
.powered h3 a{
    color: rgb(255, 255, 255) !important;
    font-size: 13px;
    padding-right: 8px !important;
}

#refundConnect{
    width: 50%;
    margin:auto;
}
.tab-content, .modal-body, .mint-goal-icon .tooltipContent{
    font-family: "CourierPrime";
}
.progressTitle h2, .progress-container span{
    font-family: "CourierPrime";
    font-size: 12px;
    font-weight: 500;
}
.progress{
    height: 0.7rem !important;
    border-radius: 15px !important;
}
.progressTitle h2{
    font-size: 13px !important;
    font-weight: 500;
    margin-bottom: 5px;
}
.spanItem span{
    font-family: 'CourierPrime' !important;
    font-weight: 800;
}

.gif-section{
    position: relative;
}
.ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }
  
  
  .ag-smoke-block {
    z-index: 4;
    position: relative;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ag-smoke-1 {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
  
    -webkit-animation: an-smoke-1 6s ease-in-out infinite;
    -moz-animation: an-smoke-1 6s ease-in-out infinite;
    -o-animation: an-smoke-1 6s ease-in-out infinite;
    animation: an-smoke-1 6s ease-in-out infinite;
  }
  .ag-smoke-2 {
    width: 57.1em;
  
    z-index: -2;
    top: 7em;
  }
  .ag-smoke-2,
  .ag-smoke-3 {
    position: absolute;
    left: 0;
    bottom: -12em !important;
    -webkit-animation: an-smoke-2 6s ease-in-out infinite;
    -moz-animation: an-smoke-2 6s ease-in-out infinite;
    -o-animation: an-smoke-2 6s ease-in-out infinite;
    animation: an-smoke-2 6s ease-in-out infinite;
  }
  .ag-smoke-3 {
    width: 100%;
  
    z-index: -4;
    top: -32em;
  }
  .ag-smoke-4 {
    width: 61.4em;
  
    z-index: -3;
    position: absolute;
    bottom: -10em;
    right: 0;
  
    -webkit-animation: an-smoke-3 6s ease-in-out infinite;
    -moz-animation: an-smoke-3 6s ease-in-out infinite;
    -o-animation: an-smoke-3 6s ease-in-out infinite;
    animation: an-smoke-3 6s ease-in-out infinite;
  }
  .ag-smoke__delay-1 {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  .ag-smoke__delay-2 {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
  }
  
  @-webkit-keyframes an-smoke-1 {
    0% {
      opacity: 0;
  
      -webkit-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translateY(0) rotate(.01deg);
      transform: translateY(0) rotate(.01deg);
    }
  }
  @-moz-keyframes an-smoke-1 {
    0% {
      opacity: 0;
  
      -moz-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -moz-transform: translateY(0) rotate(.01deg);
      transform: translateY(0) rotate(.01deg);
    }
  }
  @-o-keyframes an-smoke-1 {
    0% {
      opacity: 0;
  
      -o-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -o-transform: translateY(0) rotate(.01deg);
      transform: translateY(0) rotate(.01deg);
    }
  }
  @keyframes an-smoke-1 {
    0% {
      opacity: 0;
  
      -webkit-transform: translateY(5%) rotate(.01deg);
      -moz-transform: translateY(5%) rotate(.01deg);
      -o-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translateY(0) rotate(.01deg);
      -moz-transform: translateY(0) rotate(.01deg);
      -o-transform: translateY(0) rotate(.01deg);
      transform: translateY(0) rotate(.01deg);
    }
  }
  
  @-webkit-keyframes an-smoke-2 {
    0% {
      opacity: 0;
  
      -webkit-transform: translate(-10%, 10%) rotate(.01deg);
      transform: translate(-10%, 10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
  }
  @-moz-keyframes an-smoke-2 {
    0% {
      opacity: 0;
  
      -moz-transform: translate(-10%, 10%) rotate(.01deg);
      transform: translate(-10%, 10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -moz-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
  }
  @-o-keyframes an-smoke-2 {
    0% {
      opacity: 0;
  
      -o-transform: translate(-10%, 10%) rotate(.01deg);
      transform: translate(-10%, 10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -o-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
  }
  @keyframes an-smoke-2 {
    0% {
      opacity: 0;
  
      -webkit-transform: translate(-10%, 10%) rotate(.01deg);
      -moz-transform: translate(-10%, 10%) rotate(.01deg);
      -o-transform: translate(-10%, 10%) rotate(.01deg);
      transform: translate(-10%, 10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translateY(5%) rotate(.01deg);
      -moz-transform: translateY(5%) rotate(.01deg);
      -o-transform: translateY(5%) rotate(.01deg);
      transform: translateY(5%) rotate(.01deg);
    }
  }
  
  @-webkit-keyframes an-smoke-3 {
    0% {
      opacity: 0;
  
      -webkit-transform: translateY(10%) rotate(.01deg);
      transform: translateY(10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translate(-10%, 5%) rotate(.01deg);
      transform: translate(-10%, 5%) rotate(.01deg);
    }
  }
  @-moz-keyframes an-smoke-3 {
    0% {
      opacity: 0;
  
      -moz-transform: translateY(10%) rotate(.01deg);
      transform: translateY(10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -moz-transform: translate(-10%, 5%) rotate(.01deg);
      transform: translate(-10%, 5%) rotate(.01deg);
    }
  }
  @-o-keyframes an-smoke-3 {
    0% {
      opacity: 0;
  
      -o-transform: translateY(10%) rotate(.01deg);
      transform: translateY(10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -o-transform: translate(-10%, 5%) rotate(.01deg);
      transform: translate(-10%, 5%) rotate(.01deg);
    }
  }
  @keyframes an-smoke-3 {
    0% {
      opacity: 0;
  
      -webkit-transform: translateY(10%) rotate(.01deg);
      -moz-transform: translateY(10%) rotate(.01deg);
      -o-transform: translateY(10%) rotate(.01deg);
      transform: translateY(10%) rotate(.01deg);
    }
    50% {
      opacity: .9;
    }
    to {
      opacity: 0;
  
      -webkit-transform: translate(-10%, 5%) rotate(.01deg);
      -moz-transform: translate(-10%, 5%) rotate(.01deg);
      -o-transform: translate(-10%, 5%) rotate(.01deg);
      transform: translate(-10%, 5%) rotate(.01deg);
    }
  }
  
  
  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }
  
  }
  
  @media only screen and (max-width: 639px) {
  
  }
  
  @media only screen and (max-width: 479px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }
  
  }
  
  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }
  
  }
  


  :root {
    --color-text: #fff;
    --color-bg: #000;
    --color-link: #f9d77e;
    --color-link-hover: #fff;
    --color-info: #efc453;
    --glitch-width: 100vw;
    --glitch-height: 100vh;
    --gap-horizontal: 10px;
    --gap-vertical: 5px;
    --time-anim: 4s;
    --delay-anim: 2s;
    --blend-mode-1: none;
    --blend-mode-2: none;
    --blend-mode-3: none;
    --blend-mode-4: none;
    --blend-mode-5: overlay;
    --blend-color-1: transparent;
    --blend-color-2: transparent;
    --blend-color-3: transparent;
    --blend-color-4: transparent;
    --blend-color-5: #af4949;
  }

  
  .gglitch {
    width: 300px;
    height: 300px;
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .gglitch .glitch__item {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .gglitch .glitch__item:nth-child(n+2) {
    opacity: 0;
    animation-duration: var(--time-anim);
    animation-delay: var(--delay-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .gglitch .glitch__item:nth-child(2) {
    background-color: var(--blend-color-2);
    background-blend-mode: var(--blend-mode-2);
    animation-name: glitch-anim-2;
  }
  .gglitch .glitch__item:nth-child(2) {
    background-color: var(--blend-color-3);
    background-blend-mode: var(--blend-mode-3);
    animation-name: glitch-anim-3;
  }
  .gglitch .glitch__item:nth-child(4) {
    background-color: var(--blend-color-4);
    background-blend-mode: var(--blend-mode-4);
    animation-name: glitch-anim-4;
  }
  .gglitch .glitch__item:nth-child(5) {
    background-color: var(--blend-color-5);
    background-blend-mode: var(--blend-mode-5);
    animation-name: glitch-anim-flash;
  }
  @keyframes glitch-anim-flash {
    0%, 5% {
      opacity: 0.2;
      transform: translate3d(var(--glitch-horizontal), var(--glitch-height), 0);
    }
    5.5%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  .glitch .glitch__title {
    font-family: "Bungee", cursive;
    font-size: 100px;
    color: #ffffff;
    position: relative;
    text-transform: uppercase;
  }
  .glitch .glitch__title:before, .glitch .glitch__title:after {
    content: "GLITCH";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .glitch .glitch__title:before {
    left: 2px;
    text-shadow: -1px 0 #00ffea;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim 2s linear infinite alternate-reverse;
  }
  .glitch .glitch__title:after {
    left: -2px;
    text-shadow: -1px 0 #fe3a7f;
    clip: rect(85px, 550px, 140px, 0);
    animation: glitch-anim 2s 1s linear infinite alternate-reverse;
  }
  @keyframes glitch-anim {
    0% {
      clip: rect(10px, 9999px, 161px, 0);
    }
    5% {
      clip: rect(139px, 9999px, 77px, 0);
    }
    10% {
      clip: rect(85px, 9999px, 4px, 0);
    }
    15% {
      clip: rect(111px, 9999px, 61px, 0);
    }
    20% {
      clip: rect(45px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(112px, 9999px, 13px, 0);
    }
    30% {
      clip: rect(35px, 9999px, 67px, 0);
    }
    35% {
      clip: rect(108px, 9999px, 138px, 0);
    }
    40% {
      clip: rect(97px, 9999px, 108px, 0);
    }
    45% {
      clip: rect(72px, 9999px, 59px, 0);
    }
    50% {
      clip: rect(155px, 9999px, 56px, 0);
    }
    55% {
      clip: rect(154px, 9999px, 21px, 0);
    }
    60% {
      clip: rect(84px, 9999px, 23px, 0);
    }
    65% {
      clip: rect(113px, 9999px, 119px, 0);
    }
    70% {
      clip: rect(36px, 9999px, 156px, 0);
    }
    75% {
      clip: rect(120px, 9999px, 97px, 0);
    }
    80% {
      clip: rect(109px, 9999px, 151px, 0);
    }
    85% {
      clip: rect(81px, 9999px, 154px, 0);
    }
    90% {
      clip: rect(96px, 9999px, 33px, 0);
    }
    95% {
      clip: rect(71px, 9999px, 162px, 0);
    }
    100% {
      clip: rect(126px, 9999px, 77px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    0% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-3 {
    0% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-4 {
    0% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }




  @-webkit-keyframes text-glitch {
    1% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    1.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    2% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    2.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    3% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    3.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    4% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    4.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    5.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    6% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    7% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
      filter: grayscale(1);
    }
    7.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    8% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    9% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
     filter: none;
    }
    9.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    10% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    11% {
      clip: none;
      left: 0;
    }
   45% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -14%;
    }
    45.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
    46% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
    46.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    }
    47% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    47.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
      opacity: 0.8;
    }
   48% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    }
    48.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    }
    49% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    }
    50% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
    }
    51% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
    }
    51.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
    }
    52% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
    }
    52.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 10%;
    }
    53% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    54.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
    }
    55% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -14%;
    }
    55.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 15%;
    }
  }
  @-moz-keyframes text-glitch {
    1% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    1.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    2% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    2.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    3% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    3.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    4% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    4.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    5.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    6% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    7% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
      filter: grayscale(1);
    }
    7.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    8% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    9% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
     filter: none;
    }
    9.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    10% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    11% {
      clip: none;
      left: 0;
    }
   45% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -14%;
    }
    45.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
    46% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
    46.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    }
    47% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    47.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
      opacity: 0.8;
    }
   48% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    }
    48.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    }
    49% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    }
    50% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
    }
    51% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
    }
    51.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
    }
    52% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
    }
    52.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 10%;
    }
    53% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    54.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
    }
    55% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -14%;
    }
    55.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 15%;
    }
  }
  @-o-keyframes text-glitch {
    1% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    1.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    2% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    2.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    3% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    3.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    4% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    4.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    5.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    6% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    7% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
      filter: grayscale(1);
    }
    7.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    8% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    9% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
     filter: none;
    }
    9.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    10% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    11% {
      clip: none;
      left: 0;
    }
   45% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -14%;
    }
    45.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
    46% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
    46.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    }
    47% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    47.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
      opacity: 0.8;
    }
   48% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    }
    48.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    }
    49% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    }
    50% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
    }
    51% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
    }
    51.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
    }
    52% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
    }
    52.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 10%;
    }
    53% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    54.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
    }
    55% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -14%;
    }
    55.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 15%;
    }
  }
  @keyframes text-glitch {
    1% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    1.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    2% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    2.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    3% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    3.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    4% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    4.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    5.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    6% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    7% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
      filter: grayscale(1);
    }
    7.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    8% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    9% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
     filter: none;
    }
    9.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    10% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    11% {
      clip: none;
      left: 0;
    }
   45% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -14%;
    }
    45.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
    46% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
    46.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    }
    47% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    47.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
      opacity: 0.8;
    }
   48% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    }
    48.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    }
    49% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    }
    50% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
    }
    51% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
    }
    51.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
    }
    52% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
    }
    52.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 10%;
    }
    53% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    54.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
    }
    55% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -14%;
    }
    55.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 15%;
    }
  }
  
  @-webkit-keyframes text-glitch-2 {
    1% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    1.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    2% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    2.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    3% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    3.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    4% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    4.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    5.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    6% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    7% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    7.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    8% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    9% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    9.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    10% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    10.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    11% {
      clip: none;
      left: 0;
    }
   29% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    29.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    31% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    31.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    32% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    32.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    33% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    33.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    34% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
    }
    34.5% {
     clip: none;
     left: 0;
   }
    45.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 12%;
    }
    46% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
     opacity: 0.8;
    }
    46.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    47% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    47.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    48% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    48.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    49% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    50% {
      clip: none;
      left: 0;
    }
    50.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
    }
    51% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    51.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 11%;
    }
    52% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    53.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    54% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    54.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    55% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    55.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 15%;
    }
    56% {
     clip: none;
     left: 0;
   }
  }
  @-moz-keyframes text-glitch-2 {
    1% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    1.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    2% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    2.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    3% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    3.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    4% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    4.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    5.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    6% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    7% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    7.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    8% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    9% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    9.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    10% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    10.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    11% {
      clip: none;
      left: 0;
    }
   29% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    29.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    31% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    31.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    32% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    32.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    33% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    33.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    34% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
    }
    34.5% {
     clip: none;
     left: 0;
   }
    45.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 12%;
    }
    46% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
     opacity: 0.8;
    }
    46.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    47% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    47.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    48% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    48.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    49% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    50% {
      clip: none;
      left: 0;
    }
    50.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
    }
    51% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    51.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 11%;
    }
    52% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    53.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    54% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    54.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    55% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    55.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 15%;
    }
    56% {
     clip: none;
     left: 0;
   }
  }
  @-o-keyframes text-glitch-2 {
    1% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    1.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    2% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    2.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    3% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    3.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    4% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    4.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    5.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    6% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    7% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    7.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    8% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    9% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    9.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    10% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    10.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    11% {
      clip: none;
      left: 0;
    }
   29% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    29.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    31% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    31.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    32% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    32.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    33% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    33.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    34% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
    }
    34.5% {
     clip: none;
     left: 0;
   }
    45.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 12%;
    }
    46% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
     opacity: 0.8;
    }
    46.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    47% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    47.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    48% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    48.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    49% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    50% {
      clip: none;
      left: 0;
    }
    50.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
    }
    51% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    51.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 11%;
    }
    52% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    53.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    54% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    54.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    55% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    55.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 15%;
    }
    56% {
     clip: none;
     left: 0;
   }
  }
  @keyframes text-glitch-2 {
    1% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    1.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    2% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    2.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    3% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    3.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    4% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    4.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    5.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    6% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    6.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    7% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    7.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    8% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    8.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    9% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    9.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    10% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    10.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    11% {
      clip: none;
      left: 0;
    }
   29% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    29.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    31% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    31.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    32% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    32.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    33% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    33.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    34% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
    }
    34.5% {
     clip: none;
     left: 0;
   }
    45.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 12%;
    }
    46% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
     opacity: 0.8;
    }
    46.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    47% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    47.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    48% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    48.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    49% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    49.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    50% {
      clip: none;
      left: 0;
    }
    50.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -14%;
    }
    51% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 14%;
    }
    51.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 11%;
    }
    52% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 15%;
    }
    53.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -11%;
    }
    54% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -12%;
    }
    54.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 15%;
    }
    55% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 14%;
    }
    55.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 15%;
    }
    56% {
     clip: none;
     left: 0;
   }
  }
  
  @-webkit-keyframes text-hide {
   5% {
    filter: invert(1);
   }
   5.2% {
    filter: none;
   }
   10% {
      opacity: 0.5;
  filter: grayscale(1);
   }
   11% {
      filter: none;
    opacity: 1;
   }
   45% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   46% {
     filter: none;
    opacity: 1;
   }
  53.5% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   54% {
    filter: none;
    opacity: 1;
   }
   54.5% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(30deg);
   }
   55% {
    filter: none;
   }
   55.5% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    56% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.5;
   }
   56.5% {
    background-image:none;
   }
    57% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.8;
   }
    57.5% {
    opacity: 0.3;
   }
   58% {
   background-image:none;
   }
   
  }
  @-moz-keyframes text-hide {
   5% {
    filter: invert(1);
   }
   5.2% {
    filter: none;
   }
   10% {
      opacity: 0.5;
  filter: grayscale(1);
   }
   11% {
      filter: none;
    opacity: 1;
   }
   45% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   46% {
     filter: none;
    opacity: 1;
   }
  53.5% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   54% {
    filter: none;
    opacity: 1;
   }
   54.5% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(30deg);
   }
   55% {
    filter: none;
   }
   55.5% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    56% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.5;
   }
   56.5% {
    background-image:none;
   }
    57% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.8;
   }
    57.5% {
    opacity: 0.3;
   }
   58% {
   background-image:none;
   }
   
  }
  @-o-keyframes text-hide {
   5% {
    filter: invert(1);
   }
   5.2% {
    filter: none;
   }
   10% {
      opacity: 0.5;
  filter: grayscale(1);
   }
   11% {
      filter: none;
    opacity: 1;
   }
   45% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   46% {
     filter: none;
    opacity: 1;
   }
  53.5% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   54% {
    filter: none;
    opacity: 1;
   }
   54.5% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(30deg);
   }
   55% {
    filter: none;
   }
   55.5% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    56% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.5;
   }
   56.5% {
    background-image:none;
   }
    57% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.8;
   }
    57.5% {
    opacity: 0.3;
   }
   58% {
   background-image:none;
   }
   
  }
  @keyframes text-hide {
   5% {
    filter: invert(1);
   }
   5.2% {
    filter: none;
   }
   10% {
      opacity: 0.5;
  filter: grayscale(1);
   }
   11% {
      filter: none;
    opacity: 1;
   }
   45% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   46% {
     filter: none;
    opacity: 1;
   }
  53.5% {
    opacity: 0.5;
   -webkit-filter: grayscale(1);
   }
   54% {
    filter: none;
    opacity: 1;
   }
   54.5% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(30deg);
   }
   55% {
    filter: none;
   }
   55.5% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    56% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.5;
   }
   56.5% {
    background-image:none;
   }
    57% {
   background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    opacity: 0.8;
   }
    57.5% {
    opacity: 0.3;
   }
   58% {
   background-image:none;
   }
   
  }
  
  @-webkit-keyframes main-glitch-show {
   10% {
    visibility: visible;
    background-image: none;
   }
   15% {
    opacity: 0.2;
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
   }
   15.5% {
    opacity: 0.5;
   }
   16% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(60deg) invert(1) saturate(2.7);
   }
   16.5% {
    opacity: 0.5;
    filter: none;
   }
   17% {
    opacity: 1;
    
   }
   41.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   42% {
    filter: none;
    opacity: 1;
   }
   42.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   43% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    45% {
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
    filter: invert(1);
    opacity: 0.5;
   }
   46% {
    background-image:none;
   }
   
  }
  @-moz-keyframes main-glitch-show {
   10% {
    visibility: visible;
    background-image: none;
   }
   15% {
    opacity: 0.2;
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
   }
   15.5% {
    opacity: 0.5;
   }
   16% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(60deg) invert(1) saturate(2.7);
   }
   16.5% {
    opacity: 0.5;
    filter: none;
   }
   17% {
    opacity: 1;
    
   }
   41.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   42% {
    filter: none;
    opacity: 1;
   }
   42.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   43% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    45% {
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
    filter: invert(1);
    opacity: 0.5;
   }
   46% {
    background-image:none;
   }
   
  }
  @-o-keyframes main-glitch-show {
   10% {
    visibility: visible;
    background-image: none;
   }
   15% {
    opacity: 0.2;
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
   }
   15.5% {
    opacity: 0.5;
   }
   16% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(60deg) invert(1) saturate(2.7);
   }
   16.5% {
    opacity: 0.5;
    filter: none;
   }
   17% {
    opacity: 1;
    
   }
   41.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   42% {
    filter: none;
    opacity: 1;
   }
   42.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   43% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    45% {
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
    filter: invert(1);
    opacity: 0.5;
   }
   46% {
    background-image:none;
   }
   
  }
  @keyframes main-glitch-show {
   10% {
    visibility: visible;
    background-image: none;
   }
   15% {
    opacity: 0.2;
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
   }
   15.5% {
    opacity: 0.5;
   }
   16% {
    opacity: 0.5;
    -webkit-filter: hue-rotate(60deg) invert(1) saturate(2.7);
   }
   16.5% {
    opacity: 0.5;
    filter: none;
   }
   17% {
    opacity: 1;
    
   }
   41.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   42% {
    filter: none;
    opacity: 1;
   }
   42.5% {
    opacity: 0.5;
    filter: invert(1);
   }
   43% {
    background-image:none;
    filter: none;
    opacity: 1;
   }
    45% {
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
    filter: invert(1);
    opacity: 0.5;
   }
   46% {
    background-image:none;
   }
   
  }
  
  @-webkit-keyframes main-glitch {
    10% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    11% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    11.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    12% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    12.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    13% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    13.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    14% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    14.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    15% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    15.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    16% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    16.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    17% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    17.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    18% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    18.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    19% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    19.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    20% {
      clip: rect(60px, 9999px, 60px, 0);
    }
    20.5% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    21% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    21.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    22% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    22.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    23% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    23.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    24% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    24.5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    25% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    25.5% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    26% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    26.5% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    27%{
      clip: rect(204px, 9999px, 254px, 0);
      left: -1%;
      visibility: hidden;
    }
   43.5% {
    opacity: 0.5;
    clip: auto;
    visibility: visible;
    left: -10px;
   }
   44% {
   clip: rect(135px,999px,260px,0px);
   left: -15px;
   }
   44.5% {
    clip: rect(135px,999px,260px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: -20px;
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   
   46% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
   46.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    transform: scale(1.1);
    }
    47% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
     transform: scale(1.2);
    }
    47.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    48% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
     transform: scale(1.1);
    }
    48.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    49% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
     transform: scale(1.3);
    }
    49.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
     transform: scale(1.1);
    }
    50% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
      transform: scale(1.2);
    }
   51% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
   51.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
   52% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    transform: scale(1.1);
    }
   52.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   53% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
    transform: scale(1.1);
    }
   53.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    transform: scale(1.3);
    }
   54% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   54% {
    background-image:none;
   }
  }
  @-moz-keyframes main-glitch {
    10% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    11% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    11.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    12% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    12.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    13% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    13.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    14% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    14.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    15% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    15.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    16% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    16.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    17% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    17.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    18% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    18.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    19% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    19.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    20% {
      clip: rect(60px, 9999px, 60px, 0);
    }
    20.5% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    21% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    21.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    22% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    22.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    23% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    23.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    24% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    24.5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    25% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    25.5% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    26% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    26.5% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    27%{
      clip: rect(204px, 9999px, 254px, 0);
      left: -1%;
      visibility: hidden;
    }
   43.5% {
    opacity: 0.5;
    clip: auto;
    visibility: visible;
    left: -10px;
   }
   44% {
   clip: rect(135px,999px,260px,0px);
   left: -15px;
   }
   44.5% {
    clip: rect(135px,999px,260px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: -20px;
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   
   46% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
   46.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    transform: scale(1.1);
    }
    47% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
     transform: scale(1.2);
    }
    47.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    48% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
     transform: scale(1.1);
    }
    48.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    49% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
     transform: scale(1.3);
    }
    49.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
     transform: scale(1.1);
    }
    50% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
      transform: scale(1.2);
    }
   51% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
   51.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
   52% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    transform: scale(1.1);
    }
   52.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   53% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
    transform: scale(1.1);
    }
   53.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    transform: scale(1.3);
    }
   54% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   54% {
    background-image:none;
   }
  }
  @-o-keyframes main-glitch {
    10% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    11% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    11.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    12% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    12.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    13% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    13.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    14% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    14.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    15% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    15.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    16% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    16.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    17% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    17.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    18% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    18.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    19% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    19.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    20% {
      clip: rect(60px, 9999px, 60px, 0);
    }
    20.5% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    21% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    21.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    22% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    22.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    23% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    23.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    24% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    24.5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    25% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    25.5% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    26% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    26.5% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    27%{
      clip: rect(204px, 9999px, 254px, 0);
      left: -1%;
      visibility: hidden;
    }
   43.5% {
    opacity: 0.5;
    clip: auto;
    visibility: visible;
    left: -10px;
   }
   44% {
   clip: rect(135px,999px,260px,0px);
   left: -15px;
   }
   44.5% {
    clip: rect(135px,999px,260px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: -20px;
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   
   46% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
   46.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    transform: scale(1.1);
    }
    47% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
     transform: scale(1.2);
    }
    47.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    48% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
     transform: scale(1.1);
    }
    48.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    49% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
     transform: scale(1.3);
    }
    49.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
     transform: scale(1.1);
    }
    50% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
      transform: scale(1.2);
    }
   51% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
   51.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
   52% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    transform: scale(1.1);
    }
   52.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   53% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
    transform: scale(1.1);
    }
   53.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    transform: scale(1.3);
    }
   54% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   54% {
    background-image:none;
   }
  }
  @keyframes main-glitch {
    10% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    10.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    11% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    11.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    12% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    12.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    13% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    13.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    14% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    14.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    15% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    15.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    16% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    16.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    17% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    17.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    18% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    18.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    19% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
    }
    19.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
    20% {
      clip: rect(60px, 9999px, 60px, 0);
    }
    20.5% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    21% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    21.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    22% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    22.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    23% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    23.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    24% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    24.5% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    25% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    25.5% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    26% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    26.5% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    27%{
      clip: rect(204px, 9999px, 254px, 0);
      left: -1%;
      visibility: hidden;
    }
   43.5% {
    opacity: 0.5;
    clip: auto;
    visibility: visible;
    left: -10px;
   }
   44% {
   clip: rect(135px,999px,260px,0px);
   left: -15px;
   }
   44.5% {
    clip: rect(135px,999px,260px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: -20px;
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   
   46% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 15%;
    }
   46.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -11%;
    transform: scale(1.1);
    }
    47% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -11%;
     transform: scale(1.2);
    }
    47.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    48% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 15%;
     transform: scale(1.1);
    }
    48.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    49% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 11%;
     transform: scale(1.3);
    }
    49.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
     transform: scale(1.1);
    }
    50% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -11%;
    }
    50.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -14%;
      transform: scale(1.2);
    }
   51% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -14%;
    }
   51.5% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -14%;
    }
   52% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -12%;
    transform: scale(1.1);
    }
   52.5% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   53% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -14%;
    transform: scale(1.1);
    }
   53.5% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 14%;
    transform: scale(1.3);
    }
   54% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   54% {
    background-image:none;
   }
  }
  
  
  @-webkit-keyframes main-glitch-2 {
    10% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    10.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    11% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    11.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    12% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    12.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    13.5% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    14% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    14.5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    15% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    15.5% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    16% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    16.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    17% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    17.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    18% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    18.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    19% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    19.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    20% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    20.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
      visibility: hidden;
    }
  
   43.5% {
     clip: auto;
     visibility: visible;
     left: 10px;
     opacity:0.5;
    }
   44% {
    clip: rect(20px,999px,140px,0px);
    left: 15px;
   }
   44.5% {
    clip: rect(20px,999px,140px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: 20px;
    -webkit-filter: hue-rotate(180deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   46% {
      clip: rect(438px, 9999px, 459px, 0);
      left: 14%;
    transform: scale(1.2);
    -webkit-filter: invert(0);
    }
   46.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: 14%;
    }
   47% {
      clip: rect(448px, 9999px, 449px, 0);
      left: 14%;
    }
   47.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: 12%;
    transform: scale(1.1);
    }
   48% {
      clip: rect(466px, 9999px, 526px, 0);
      left: 11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   48.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: 14%;
    transform: scale(1.1);
    }
   49% {
      clip: rect(174px, 9999px, 268px, 0);
      left: -14%;
    transform: scale(1.3);
    }
   49.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: -12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   50% {
      clip: rect(308px, 9999px, 311px, 0);
      left: -15%;
    }
   50.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: 11%;
    transform: scale(1.1);
    }
    51% {
      clip: rect(54px, 9999px, 92px, 0);
      left: 11%;
     transform: scale(1.2);
    }
    51.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: -13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    52% {
      clip: rect(354px, 9999px, 394px, 0);
      left: -15%;
     transform: scale(1.1);
    }
    52.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: 11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    53% {
      clip: rect(9px, 9999px, 29px, 0);
      left: -11%;
     transform: scale(1.3);
    }
   54% {
    background-image:none;
   }
  }
  @-moz-keyframes main-glitch-2 {
    10% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    10.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    11% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    11.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    12% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    12.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    13.5% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    14% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    14.5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    15% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    15.5% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    16% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    16.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    17% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    17.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    18% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    18.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    19% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    19.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    20% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    20.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
      visibility: hidden;
    }
  
   43.5% {
     clip: auto;
     visibility: visible;
     left: 10px;
     opacity:0.5;
    }
   44% {
    clip: rect(20px,999px,140px,0px);
    left: 15px;
   }
   44.5% {
    clip: rect(20px,999px,140px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: 20px;
    -webkit-filter: hue-rotate(180deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   46% {
      clip: rect(438px, 9999px, 459px, 0);
      left: 14%;
    transform: scale(1.2);
    -webkit-filter: invert(0);
    }
   46.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: 14%;
    }
   47% {
      clip: rect(448px, 9999px, 449px, 0);
      left: 14%;
    }
   47.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: 12%;
    transform: scale(1.1);
    }
   48% {
      clip: rect(466px, 9999px, 526px, 0);
      left: 11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   48.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: 14%;
    transform: scale(1.1);
    }
   49% {
      clip: rect(174px, 9999px, 268px, 0);
      left: -14%;
    transform: scale(1.3);
    }
   49.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: -12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   50% {
      clip: rect(308px, 9999px, 311px, 0);
      left: -15%;
    }
   50.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: 11%;
    transform: scale(1.1);
    }
    51% {
      clip: rect(54px, 9999px, 92px, 0);
      left: 11%;
     transform: scale(1.2);
    }
    51.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: -13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    52% {
      clip: rect(354px, 9999px, 394px, 0);
      left: -15%;
     transform: scale(1.1);
    }
    52.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: 11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    53% {
      clip: rect(9px, 9999px, 29px, 0);
      left: -11%;
     transform: scale(1.3);
    }
   54% {
    background-image:none;
   }
  }
  @-o-keyframes main-glitch-2 {
    10% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    10.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    11% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    11.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    12% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    12.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    13.5% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    14% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    14.5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    15% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    15.5% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    16% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    16.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    17% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    17.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    18% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    18.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    19% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    19.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    20% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    20.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
      visibility: hidden;
    }
  
   43.5% {
     clip: auto;
     visibility: visible;
     left: 10px;
     opacity:0.5;
    }
   44% {
    clip: rect(20px,999px,140px,0px);
    left: 15px;
   }
   44.5% {
    clip: rect(20px,999px,140px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: 20px;
    -webkit-filter: hue-rotate(180deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   46% {
      clip: rect(438px, 9999px, 459px, 0);
      left: 14%;
    transform: scale(1.2);
    -webkit-filter: invert(0);
    }
   46.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: 14%;
    }
   47% {
      clip: rect(448px, 9999px, 449px, 0);
      left: 14%;
    }
   47.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: 12%;
    transform: scale(1.1);
    }
   48% {
      clip: rect(466px, 9999px, 526px, 0);
      left: 11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   48.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: 14%;
    transform: scale(1.1);
    }
   49% {
      clip: rect(174px, 9999px, 268px, 0);
      left: -14%;
    transform: scale(1.3);
    }
   49.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: -12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   50% {
      clip: rect(308px, 9999px, 311px, 0);
      left: -15%;
    }
   50.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: 11%;
    transform: scale(1.1);
    }
    51% {
      clip: rect(54px, 9999px, 92px, 0);
      left: 11%;
     transform: scale(1.2);
    }
    51.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: -13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    52% {
      clip: rect(354px, 9999px, 394px, 0);
      left: -15%;
     transform: scale(1.1);
    }
    52.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: 11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    53% {
      clip: rect(9px, 9999px, 29px, 0);
      left: -11%;
     transform: scale(1.3);
    }
   54% {
    background-image:none;
   }
  }
  @keyframes main-glitch-2 {
    10% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    10.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    11% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    11.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    12% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    12.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    13.5% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    14% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    14.5% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    15% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    15.5% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    16% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    16.5% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    17% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    17.5% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    18% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    18.5% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    19% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    19.5% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
    }
    20% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
    }
    20.5% {
      clip: rect(430px, 9999px, 442px, 0);
      left: 5%;
      visibility: hidden;
    }
  
   43.5% {
     clip: auto;
     visibility: visible;
     left: 10px;
     opacity:0.5;
    }
   44% {
    clip: rect(20px,999px,140px,0px);
    left: 15px;
   }
   44.5% {
    clip: rect(20px,999px,140px,0px);
    left: 0;
   }
   45% {
    clip: auto;
    left: 20px;
    -webkit-filter: hue-rotate(180deg) saturate(1.3);
   }
   45.5% {
    left: 0px;
    -webkit-filter: invert(1);
   }
   46% {
      clip: rect(438px, 9999px, 459px, 0);
      left: 14%;
    transform: scale(1.2);
    -webkit-filter: invert(0);
    }
   46.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: 14%;
    }
   47% {
      clip: rect(448px, 9999px, 449px, 0);
      left: 14%;
    }
   47.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: 12%;
    transform: scale(1.1);
    }
   48% {
      clip: rect(466px, 9999px, 526px, 0);
      left: 11%;
    transform: scale(1.3);
    -webkit-filter: hue-rotate(90deg) saturate(1.3);
    }
   48.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: 14%;
    transform: scale(1.1);
    }
   49% {
      clip: rect(174px, 9999px, 268px, 0);
      left: -14%;
    transform: scale(1.3);
    }
   49.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: -12%;
    transform: scale(1.1);
    -webkit-filter:none;
    }
   50% {
      clip: rect(308px, 9999px, 311px, 0);
      left: -15%;
    }
   50.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: 11%;
    transform: scale(1.1);
    }
    51% {
      clip: rect(54px, 9999px, 92px, 0);
      left: 11%;
     transform: scale(1.2);
    }
    51.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: -13%;
     transform: scale(1.3);
     -webkit-filter: invert(0);
    }
    52% {
      clip: rect(354px, 9999px, 394px, 0);
      left: -15%;
     transform: scale(1.1);
    }
    52.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: 11%;
     transform: scale(1.2);
     -webkit-filter: none;
    }
    53% {
      clip: rect(9px, 9999px, 29px, 0);
      left: -11%;
     transform: scale(1.3);
    }
   54% {
    background-image:none;
   }
  }
  
  @-webkit-keyframes CTA-glitch {
    71% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    71.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    72% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    72.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    73% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    73.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    74% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    74.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    75% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    75.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    76% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    77% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    77.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    78% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    79% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    80% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
  
    }
    80.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
   81% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    81.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    82% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    82.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    83% {
      clip: none;
      left: 0;
    }
  }
  @-moz-keyframes CTA-glitch {
    71% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    71.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    72% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    72.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    73% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    73.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    74% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    74.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    75% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    75.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    76% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    77% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    77.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    78% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    79% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    80% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
  
    }
    80.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
   81% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    81.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    82% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    82.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    83% {
      clip: none;
      left: 0;
    }
  }
  @-o-keyframes  CTA-glitch {
    71% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    71.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    72% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    72.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    73% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    73.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    74% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    74.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    75% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    75.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    76% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    77% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    77.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    78% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    79% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    80% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
  
    }
    80.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
   81% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    81.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    82% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    82.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    83% {
      clip: none;
      left: 0;
    }
  }
  @keyframes CTA-glitch {
    71% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    71.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    72% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    72.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    73% {
      clip: rect(466px, 9999px, 526px, 0);
      left: -1%;
    }
    73.5% {
      clip: rect(263px, 9999px, 296px, 0);
      left: -4%;
    }
    74% {
      clip: rect(174px, 9999px, 268px, 0);
      left: 4%;
    }
    74.5% {
      clip: rect(100px, 9999px, 167px, 0);
      left: 2%;
    }
    75% {
      clip: rect(308px, 9999px, 311px, 0);
      left: 5%;
    }
    75.5% {
      clip: rect(131px, 9999px, 147px, 0);
      left: -1%;
    }
    76% {
      clip: rect(54px, 9999px, 92px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(341px, 9999px, 421px, 0);
      left: 3%;
    }
    77% {
      clip: rect(354px, 9999px, 394px, 0);
      left: 5%;
    }
    77.5%{
      clip: rect(260px, 9999px, 360px, 0);
      left: -1%;
    }
    78% {
      clip: rect(9px, 9999px, 29px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(264px, 9999px, 322px, 0);
      left: 0%;
    }
    79% {
      clip: rect(51px, 9999px, 142px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(105px, 9999px, 151px, 0);
      left: -4%;
    }
    80% {
      clip: rect(204px, 9999px, 237px, 0);
      left: -4%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
  
    }
    80.5% {
      clip: rect(298px, 9999px, 317px, 0);
      left: 5%;
    }
   81% {
      clip: rect(438px, 9999px, 459px, 0);
      left: -4%;
    }
    81.5% {
      clip: rect(279px, 9999px, 294px, 0);
      left: -4%;
    }
    82% {
      clip: rect(448px, 9999px, 449px, 0);
      left: -4%;
    }
    82.5% {
      clip: rect(301px, 9999px, 345px, 0);
      left: -2%;
    }
    83% {
      clip: none;
      left: 0;
    }
  }
  
  @-webkit-keyframes CTA-glitch-2 {
    70% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    71.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    72% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    72.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    73% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    73.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    74% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    74.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    75% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    75.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    76% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    77% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    77.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    78% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    79% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    80% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
    }
    80.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
      filter: invert(1);
    }
   81% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
     filter: none;
    
    }
    81.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    82% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    82.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    83% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    83.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    84% {
      clip: none;
      left: 0;
    }
  }
  @-moz-keyframes CTA-glitch-2 {
    70% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    71.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    72% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    72.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    73% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    73.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    74% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    74.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    75% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    75.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    76% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    77% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    77.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    78% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    79% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    80% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
    }
    80.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
      filter: invert(1);
    }
   81% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
     filter: none;
    
    }
    81.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    82% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    82.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    83% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    83.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    84% {
      clip: none;
      left: 0;
    }
  }
  @-o-keyframes CTA-glitch-2 {
    70% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    71.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    72% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    72.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    73% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    73.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    74% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    74.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    75% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    75.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    76% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    77% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    77.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    78% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    79% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    80% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
    }
    80.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
      filter: invert(1);
    }
   81% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
     filter: none;
    
    }
    81.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    82% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    82.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    83% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    83.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    84% {
      clip: none;
      left: 0;
    }
  }
  @keyframes CTA-glitch-2 {
    70% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
    }
    71.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    72% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    72.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    73% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    73.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    74% {
      clip: rect(188px, 9999px, 194px, 0);
      left: -3%;
    }
    74.5% {
      clip: rect(236px, 9999px, 241px, 0);
      left: -3%;
    }
    75% {
      clip: rect(190px, 9999px, 199px, 0);
      left: -1%;
    }
    75.5% {
      clip: rect(342px, 9999px, 384px, 0);
      left: 5%;
    }
    76% {
      clip: rect(436px, 9999px, 459px, 0);
      left: -1%;
    }
    76.5% {
      clip: rect(202px, 9999px, 240px, 0);
      left: 2%;
    }
    77% {
      clip: rect(278px, 9999px, 296px, 0);
      left: -4%;
    }
    77.5% {
      clip: rect(181px, 9999px, 191px, 0);
      left: 4%;
    }
    78% {
      clip: rect(29px, 9999px, 36px, 0);
      left: 1%;
    }
    78.5% {
      clip: rect(338px, 9999px, 366px, 0);
      left: 5%;
    }
    79% {
      clip: rect(335px, 9999px, 360px, 0);
      left: -1%;
    }
    79.5% {
      clip: rect(282px, 9999px, 297px, 0);
      left: -2%;
    }
    80% {
      clip: rect(56px, 9999px, 101px, 0);
      left: 5%;
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
    }
    80.5% {
      clip: rect(136px, 9999px, 167px, 0);
      left: 4%;
      filter: invert(1);
    }
   81% {
      clip: rect(204px, 9999px, 254px, 0);
      left: -2%;
     filter: none;
    
    }
    81.5% {
      clip: rect(197px, 9999px, 236px, 0);
      left: -4%;
    }
    82% {
      clip: rect(452px, 9999px, 474px, 0);
      left: 0%;
    }
    82.5% {
      clip: rect(51px, 9999px, 57px, 0);
      left: -2%;
    }
    83% {
      clip: rect(108px, 9999px, 119px, 0);
      left: 2%;
    }
    83.5% {
      clip: rect(458px, 9999px, 480px, 0);
      left: -3%;
    }
    84% {
      clip: none;
      left: 0;
    }
  }
  
  
  @-webkit-keyframes CTA-show {
   56% {
    visibility: visible;
    opacity: 0.5;
   }
   56.5% {
   filter: brightness(1.8) grayscale(0.7) hue-rotate(60deg);
   }
   57% {
    filter: none;
   }
   57.5%{
    -webkit-filter: invert(1);
    opacity: 0.8;
   }
   58% {
    filter: none;
   }
   59% {
    opacity: 1;
   }
   78% {
   filter: brightness(3.4) grayscale(1);
   }
   79% {
    filter: none;
   }
   80% {
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
   }
  }
  @-moz-keyframes CTA-show {
   56% {
    visibility: visible;
    opacity: 0.5;
   }
   56.5% {
   filter: brightness(1.8) grayscale(0.7) hue-rotate(60deg);
   }
   57% {
    filter: none;
   }
   57.5%{
    -webkit-filter: invert(1);
    opacity: 0.8;
   }
   58% {
    filter: none;
   }
   59% {
    opacity: 1;
   }
   78% {
   filter: brightness(3.4) grayscale(1);
   }
   79% {
    filter: none;
   }
   80% {
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
   }
  }
  @-o-keyframes CTA-show {
   56% {
    visibility: visible;
    opacity: 0.5;
   }
   56.5% {
   filter: brightness(1.8) grayscale(0.7) hue-rotate(60deg);
   }
   57% {
    filter: none;
   }
   57.5%{
    -webkit-filter: invert(1);
    opacity: 0.8;
   }
   58% {
    filter: none;
   }
   59% {
    opacity: 1;
   }
   78% {
   filter: brightness(3.4) grayscale(1);
   }
   79% {
    filter: none;
   }
   80% {
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
   }
  }
  @keyframes CTA-show {
   56% {
    visibility: visible;
    opacity: 0.5;
   }
   56.5% {
   filter: brightness(1.8) grayscale(0.7) hue-rotate(60deg);
   }
   57% {
    filter: none;
   }
   57.5%{
    -webkit-filter: invert(1);
    opacity: 0.8;
   }
   58% {
    filter: none;
   }
   59% {
    opacity: 1;
   }
   78% {
   filter: brightness(3.4) grayscale(1);
   }
   79% {
    filter: none;
   }
   80% {
     background-image: url(https://anonymousfox.enleap.app/asset/5.png);
   }
  }
  
  /*text */
  
  .text-inner, .text-inner-2 {
    width: 100%;
    height: 100%;
    background-image: url(https://anonymousfox.enleap.app/asset/4.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
  }
  
  .text {
    position: absolute;
    overflow: hidden;
    z-index: -10;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background-image:url(https://anonymousfox.enleap.app/asset/4.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
    -webkit-animation: text-hide 15s infinite step-end;
            animation: text-hide 15s infinite step-end;
  
  }
  
  
  .text-inner {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: rect(60px, 9999px, 60px, 0);
    -webkit-animation: text-glitch 15s infinite step-end;
            animation: text-glitch 15s infinite step-end;
  
  }
  
  .text-inner-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: rect(60px, 9999px, 60px, 0);
    -webkit-animation: text-glitch-2 15s infinite step-end;
            animation: text-glitch-2 15s infinite step-end;
  
  }
  
  /*main image */
  
  .glitch-inner, .glitch-inner-2 {
    width: 100%;
    height: 100%;
    background-image: url(https://anonymousfox.enleap.app/asset/1.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
  }
  
  .glitch {
    position: absolute;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    z-index: 0;
    visibility:hidden;
    background-image: url(https://anonymousfox.enleap.app/asset/5.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
    -webkit-animation: main-glitch-show 15s infinite step-end;
            animation: main-glitch-show 15s infinite step-end;
  }
  
  
  .glitch-inner {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: auto;
    -webkit-animation: main-glitch 15s infinite step-end;
            animation: main-glitch 15s infinite step-end;
  
  }
  
  .glitch-inner-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: auto;
    -webkit-animation: main-glitch-2 15s infinite step-end;
            animation: main-glitch-2 15s infinite step-end;
  
  }
  
  .center {
   margin: 0 auto;
  }
  
  
  /* CTA */
  
  .CTA-inner, .CTA-inner-2 {
    width: 100%;
    height: 100%;
    background-image: url(https://anonymousfox.enleap.app/asset/4.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
  }
  
  .CTA {
    position: absolute;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    z-index: 0;
    visibility:hidden;
    background-image: url(https://anonymousfox.enleap.app/asset/4.png);
    background-size: 100% auto;
    background-repeat:no-repeat !important;
    -webkit-animation: CTA-show 15s infinite step-end;
            animation: CTA-show 15s infinite step-end;
  }
  
  
  .CTA-inner {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: auto;
    -webkit-animation: CTA-glitch 15s infinite step-end;
            animation: CTA-glitch 15s infinite step-end;
  
  }
  
  .CTA-inner-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    clip: auto;
    -webkit-animation: CTA-glitch-2 15s infinite step-end;
            animation: CTA-glitch-2 15s infinite step-end;
  
  }